<template>
  <div data-app class="container" style="width: 90%">
    <v-form
      v-model="valid"
      ref="form1"
      lazy-validation
      id="accountType"
      class="mb-15"
    >
      <div>
        <span class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
          >Forgot Password</span
        >
        <br />
        <span
          >Please enter your email and we'll send you a link to reset your
          password.</span
        >
        <div class="form-group mb-form">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- <span>
        <a role="button" tabindex="0"
          >By Clicking Sign In,you agee to Choice Home Care Terms &
          Conditions</a
        >
      </span> -->
      <br />
      <div class="text-center">
        <v-btn
          rounded
          class="btn btn-success mr-3 mb-9"
          style="width: 100%; color: #ffff; height: 60px"
          @click="SignIn()"
        >
          Submit Reset Link
        </v-btn>
      </div>
      <span class="float-left"
        ><a role="button" tabindex="0" @click="Login()"
          >Back to Sign In</a
        ></span
      >
    </v-form>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";

var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data() {
    return {
      valid: true,
      email: "",
      show1: false,
      password: "",
    };
  },
  mounted() {},
  computed: {},
  destroyed() {},
  methods: {
    next() {
      this.$v.$touch();
      if (this.$refs.form.validate()) {
        this.showForm2 = true;
        this.showForm1 = false;
      }
    },
    createAccount() {
      this.$router.push({ name: "signUp" });
    },
    Login() {
      this.$router.push({ name: "login" });
    },
    SignIn() {
      this.$v.$touch();
       if (this.$v.email.$invalid) {
        Swal.fire({
          icon: "error",
          title: "Invalid Email",
          text: "Please enter a valid email address",
        });
        return;
      } else {
        this.$store.dispatch("ResetPassword", this.email).then((response) => {
          if (response.status) {
            Swal.fire({
              icon: "success",
              title: "",
              text: response.message,
            }).then(() => {
              this.$router.push({ name: "dashboard" });
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.message,
            });
          }
        });
      }
    },
  },
};
</script>
<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
</style>
